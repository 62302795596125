import React, { useState } from "react";
import "../styles/dateStyles.css";
import { FaChevronDown } from "react-icons/fa";

export default function Date({ dateValue, setDateValue, dates }) {
  const [datesOpen, setDatesOpen] = useState(false);
  return (
    <div className="dateContainer">
      <div
        className="dateBox"
        onClick={() => {
          setDatesOpen(!datesOpen);
        }}
      >
        {dateValue} <FaChevronDown className="downArrow"/>
      </div>

      {datesOpen && (
        <div className="datesDropdown">
          {dates.map((date) => (
            <>
              <div
                onClick={() => {
                  setDateValue(date);
                  setDatesOpen(false);
                }}
                className="dateDropdownVal"
              >
                {date}
              </div>
            </>
          ))}
        </div>
      )}
    </div>
  );
}
