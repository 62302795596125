import React, { useEffect, useRef } from "react";
import "../styles/style.css";
import { gsap } from "gsap";

export default function Main({ data, dateValue }) {
  let mobile_media_query = window.matchMedia("(max-width: 400px)");
  let tablet_media_query = window.matchMedia(
    "(min-width: 400px) and (max-width: 600px)"
  );

  // Refs
  const upPaperRef = useRef(null);

  // Function that resets the size of the notes.
  const recize_notes = () => {
    const notes = document.querySelectorAll(".js-note");

    for (let i = 0; i < notes.length; i++) {
      if (notes[i].classList.contains("active")) {
        notes[i].classList.remove("active");
        gsap.set(notes[i], {
          height: "30%",
          clearProps: "all",
        });
      }
    }
  };

  // Function that enables all the notes.
  const notes_ready = () => {
    const notes = document.querySelectorAll(".js-note");
    gsap.to(".js-envelop-content", {
      height: "110%",
      duration: 0.5,
    });

    for (let i = 0; i < notes.length; i++) {
      notes[i].addEventListener("click", function () {
        if (mobile_media_query.matches) {
          if (this.classList.contains("active")) {
            this.classList.remove("active");
            gsap.set(this, {
              height: "30%",
              clearProps: "all",
            });
          } else {
            for (let i = 0; i < notes.length; i++) {
              if (notes[i].classList.contains("active")) {
                notes[i].classList.remove("active");
                gsap.set(notes[i], {
                  height: "30%",
                  clearProps: "all",
                });
              }
            }
            this.classList.add("active");
            gsap.set(this, {
              height: 120 + 20 * i + "%",
            });
          }
        } else if (tablet_media_query.matches) {
          if (this.classList.contains("active")) {
            this.classList.remove("active");
            gsap.set(this, {
              height: "30%",
              clearProps: "all",
            });
          } else {
            for (let i = 0; i < notes.length; i++) {
              if (notes[i].classList.contains("active")) {
                notes[i].classList.remove("active");
                gsap.set(notes[i], {
                  height: "30%",
                  clearProps: "all",
                });
              }
            }
            this.classList.add("active");
            gsap.set(this, {
              height: 120 + 20 * i + "%",
            });
          }
        } else {
          if (this.classList.contains("active")) {
            this.classList.remove("active");
            gsap.set(this, {
              height: "30%",
              clearProps: "all",
            });
          } else {
            for (let i = 0; i < notes.length; i++) {
              if (notes[i].classList.contains("active")) {
                notes[i].classList.remove("active");
                gsap.set(notes[i], {
                  height: "30%",
                  clearProps: "all",
                });
              }
            }
            this.classList.add("active");
            gsap.set(this, {
              height: 90 + 15 * i + "%",
            });
          }
        }
      });
    }
  };

  // Function that set up the up paper of the envelope.
  const set_up_paper = () => {
    var arr = [0, 0, 100, 0, 50, 61];
    gsap.set(".js-up-paper", {
      bottom: "97%",
      rotation: 180,
      zIndex: 200,
      clipPath: "polygon(" + arr.join("%,") + "%)",
      onComplete: notes_ready,
    });
  };

  // Function that starts the up paper transition.
  const envelop_transition = () => {
    gsap.to(".js-up-paper", {
      bottom: "1%",
      duration: 0.25,
      onComplete: set_up_paper,
    });
    document
      .querySelector(".js-up-paper")
      .removeEventListener("click", envelop_transition);
    document.querySelector(".js-up-paper").classList.remove("cursor");
  };

  // Function that allows cutting the sticker.
  const sticker = () => {
    gsap.set(".js-sticker", { width: "20%", left: "-80%" });
    const body = document.getElementsByClassName("scissors")[0];
    body.classList.remove("scissors");
    document.querySelector(".js-sticker").removeEventListener("click", sticker);
    document
      .querySelector(".js-up-paper")
      .addEventListener("click", envelop_transition);
    document.querySelector(".js-up-paper").classList.add("cursor");
  };

  // Add event listeners and initial setup
  useEffect(() => {
    document.querySelector(".js-sticker").addEventListener("click", sticker);
    window.onresize = () => recize_notes();
    return () => {
      window.onresize = null;
    };
  }, []); // Empty dependency array to run the effect once when the component mounts

  return (
    <div className="scissors">
      <div className="envelop">
        <div className="envelop__front-paper"></div>
        <div className="envelop__back-paper"></div>
        <div ref={upPaperRef} className="envelop__up-paper js-up-paper"></div>
        <div className="envelop__sticker js-sticker"></div>
        <div className="envelop__false-sticker"></div>
        <div className="envelop__content js-envelop-content">
          <div className="love-notes">
            <div className="note js-note">
              <div className="note__text">
                <div
                  style={{
                    fontSize: "0.95rem",
                    textAlign: "right",
                    fontStyle: "italic",
                  }}
                >
                  {dateValue}
                </div>

                {data[0]}
              </div>
            </div>
            <div className="note js-note">
              <div className="note__text">{data[1]}</div>
            </div>
            <div className="note js-note">
              <div className="note__text">{data[2]}- Your Sunshine 😘☀️</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
