import im1 from "./images/29-02.jpeg";
import im2 from "./images/29_02_2.jpeg";

export const messagesData = {
  "24-04-2024": [
    <p>
      Good morninggg my babbyyy 💖💖💖💖, <br />I lovveeeee youuuuu thee most my
      babbyy... I know that this is a difficult time for you and you have nooo
      ideaa how badly I wsih I was by your side right now, holding you my arms,
      giving you a kiss on the forehead, and telling you that everything weill
      be allright.
    </p>,
    <p>
      Even if I cant be there with you right now baby, I want you to know
      babyyy, that no matter what happens I am going to be right here to support
      you every step of the way, give you all the love and care that you need
      babyy, because you deserve nothing less than the best babbyy!!💞💞💞💞
      <br /> And I want to be that best for you darling!
    </p>,
    <p>
      I am really sorry for anything I did that caused you pain baby, and I
      promise that I will always try my best to be the reason for your smile and
      not your tears babyy, because your smile is my favourite thing in the
      entire world babbyy, and mein usse kabhi gayab nahi hone dungaa... ❤️❤️
    </p>,
  ],
  "29-02-2024": [
    <p>
      Heyy babbyy ♥️♥️♥️♥️♥️ ,<br />
      I am really soorry this hasn't been updated in so long baby.. I know
      you've had a reallyy long and tiring day at work babbyy... <br />
      And while I have missed youu soooooo muchh throughout the day, more than
      missing you I honestly feel sooo proud of you, you have no idea babbyy.
      Every single day you have been..
    </p>,
    <p>
      Working soo hard, office ka kaam, khud ka dhyaan rakhna, merko itna pyaar
      dena... You do everything perfectly babyy and I am soo grateful honestly
      to call you Mine!!💖💖💖 <br />I want you to know that I am always there
      for you and I am always gonna be there to support you and love you as much
      as I can! 🌎🌎🌎 I know that for the last 4-5 days things haven't been the
      best for us babyy... But I promise...
    </p>,
    <p>
      that they'll get better soon and I'll never give up on us darling! <br />
      <img src={im1} style={{ height: "11rem" }}></img>
      &nbsp; &nbsp;
      <img src={im2} style={{ height: "11rem" }}></img>
      <br />I LOVE YOU! 💞💞💞
    </p>,
  ],
  "17-02-2024": [
    <p>
      Hiiiii myy cutieeee pieeeeeee💖💖💖💖💖💖 <br />
      Gooood Morrningg babbyyyy ♥️♥️♥️♥️♥️♥️ <br /> <br />
      I am missssingggg youuuuu soooooo sooooo muchhh rn babbyyy and I knoww you
      are too darling!! ♥️♥️♥️♥️
      <br />
      You knoww babbyy abhi na I was sitting and looking through the Valentine's
      day ke photos...
    </p>,
    <p>
      we took babbyy... toh thoda better feel hua voh dekh ke babbyyy... I also
      thought about the fight we had that day, along with all the good memories
      we made babyy. Fir I saw my phone ka wallpaper, myself with your parents
      babyy, and voh dekh ke bas merko feel hua ki theek hai fights toh ti
      rahengi, problems aayengi, aur hum solve karenge but this is 💞FOREVER💞,
      no matter what happens baby,...
    </p>,
    <p>
      I want them to be my family, I want to be with you forever, make millions
      of good memories more and face all the bad times together too Tishuu!!
      <br />
      <br />
      I lovveee youuu sooo muchh !!! 💖💖💖💖💖 <br />
      <br />
      Have a great day at work babbyy!! ♥️♥️♥️♥️♥️
    </p>,
  ],
  "15-02-2024": [
    <p>
      Hii babyyyy, so yesterday aapko itne time kal finalllyyyy mil ke darling
      you have nooo ideaa merko kitna achha lagaaa... obviously babyy aapko bye
      bol ke, taxi mein baith ke on my way to the station bohut bura lag rha tha
      babbyyy... but I was just thinking about all the happyyyy memories we'd
      made in the last few hours....
    </p>,
    <p>
      Merko na kuch cheezein aur samajh aayi hai babbyy, ki no matter how bad
      things, how much ever we fight baby, we are not ending this babyy. This is
      final and forever!!♥️♥️♥️♥️ <br />
      Also I know how much you have done for me and for this relationship babyy
      and I promise that one day I will make it all worth it for you by giving
      you the life and the family that you deserve. 💞💞💞💞
    </p>,
    <p>
      So babbyy... please always remember that I LOVE YOU so so muchh... and I
      know ki ek dusre se dur rehna bohut mushkil but pakka things will get
      better soon Tishuu!! I Loveeee youuuu thee mossteesstt!! I hope I was able
      to give you thoda sa happiness and good memories yesterday! ♥️💞
    </p>,
  ],
  "13-02-2024": [
    <p>
      Hii my babbyyy💞💞 <br /> First of all I just want to apologize ki kal
      idhar kuch nahi likha... I am reallyyy sorryy bubuuu.
      <br />
      Well I just wanted to say thank you Trisha,
      <br />
      ♥️ Thank you for last night's conversation ♥️ <br />
      ♥️Thank you for not giving up on us♥️
      <br />
      ♥️ Thank you for always being there for me!♥️
    </p>,
    <p>
      Well babbyy, today is finallllyyyy your first proper day going to office,
      and I am sooooo proud of you buubuuu. Thee prouddesssstt!!! 💞💞💞💞{" "}
      <br />I see every single how hard you're working baby, I know it has not
      been easy for you, far away from your family, all by yourself in a new
      city, and you are killing it babbyyy!! 🔥🔥♥️♥️
    </p>,
    <p>
      Alll the besstt for your first day at office babbyyy♥️ <br />I just hope
      that one day I can always love you, support you, and be a good partner to
      you babyy, like you have been for me. I know I am not perfect babyyy, I
      know no one is perfect but yaar... I want to be perfect for you bubss, so
      that I can marry you and keep you happy for the rest of our lives
      bubuu💞💞💞
    </p>,
  ],
  "11-02-2024": [
    <p>
      Hi my babbyy, I know its sunday aaj toh aap 11 baje tak hi uthenge 🤭🤭🤭{" "}
      <br />
      But that's good bubuu, I know you had a long and hectic day at work and
      you need to rest darling! ❤️❤️❤️❤️
      <br />
      I've been up subah se, thinking about you, about us babbyy... I was aise
      hi scrolling through my photos and I realised how much we both have
      changed over the last 3 years almost babyy.
    </p>,
    <p>
      It makes me feel so proud of us you know babyy, how despite all the
      difficulties we've faced along the way we both chose never to give up
      babbyy... and I know that bhi aisa hi hoga which is why I am so sure that
      no matter what happens I want to spend the rest of my life with you
      babbyy. <br />
      Well tomorrow bubuu is your 'Campus to Corporate' and I am sooo sooooo
      excited for you, I have seen you grow so much...
    </p>,
    <p>
      over the course of our relationship. And now seeing you work so hard, and
      doing so well babyy I am feeling sooooo verryyy proud of you, You have noo
      ideaa!! <br />
      And I am just praying that you keep growing, getting better and loving
      your work more and more babbyy. <br />
      You Are My Everything!❤️❤️❤️❤️❤️ <br />I Loveeee Youu!! 💞💞💞💞💞💞💞
    </p>,
  ],
  "10-02-2024": [
    <p>
      Hi my babbyy, So I finally met your parents yesterday...
      <br />
      I know it all happened so quickly baby, and was for a very short while but
      I am cherishing every singly moment of it babbyyy! <br />I know what a
      huge moment this is for you and I want you to understand that it is
      equally important for me to babbyy!!❤️❤️
    </p>,
    <p>
      I know we fight a lot baby, about a lot of different things baby, but
      after yesterday, all those fights just seem so small compared to the rest
      of our lives that we are going to spend together! <br />I want to become a
      better partner for you darling and I know ki as long as you are by my side
      we will continue to grow together, improving this relationship every
      single day! 🌍💓
    </p>,
    <p>
      I honestly can't stop thinking about the 5 minutes that I spent with your
      parents and how you and them are going to be my family forever babyy!!
      💞💞💞💞 <br />
      I love you the most babeee and I seriously can't wait to spend the rest of
      my life with you!! ❤️ <br />
    </p>,
  ],
  "09-02-2024": [
    <p>
      Hi my babbyy, <br />
      I just want to tell you how much I love you. <br />
      I know I hurt you baby, but please trust me na mein jab bhi aapko hurt
      karta hu na babyy... usse merko bhi bohut zyada pain hota hai.
      <br />I know I am not the easiest person to deal with, not the easiest
      partner to have, and yet you have always been there for me Trisha...
    </p>,
    <p>
      and that is why I consider myself to be the luckiest person alive.
      <br />
      Thank you so much for everything that you have done for me babyyy💞💞💞{" "}
      <br />
      I promise to try my best Trisha to be the best partner possible for you,
      learn from my mistakes and I want both of us to grow together for the rest
      of our lives babyyy.
      <br />
      You've always been the only constant source of motivation in my life..
    </p>,
    <p>
      always pushed me to be the best I could be. <br />
      And I know that as long as I have you by my side I will always try to be
      better than I can and I hope that I can help you too babbyyy💞💞
      <br />
      And for all of this darling I can never thank you enough❤️❤️.
    </p>,
  ],
};
