import "./App.css";
import Main from "./components/Main";
import { useState } from "react";
import { messagesData } from "./data/data";
import Date from "./components/Date";
import Footer from "./components/Footer";
function App() {
  const [dateValue, setDateValue] = useState(Object.keys(messagesData)[0]);
  return (
    <div className="App">
      <Date
        dateValue={dateValue}
        setDateValue={setDateValue}
        dates={Object.keys(messagesData)}
      />
      <Main data={messagesData[dateValue]} dateValue={dateValue} />
      <Footer />
    </div>
  );
}

export default App;
